import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./companyName.css";

function CompanyName() {
  const { id } = useParams(); // Get the ID from the URL
  const [investment, setInvestment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("about");

  useEffect(() => {
    // Dummy data based on the document requirements
    const dummyInvestment = {
      id,
      name: "Favcy",
      logo_url: "https://img.icons8.com/fluency/48/startup.png",
      banner_url: "https://via.placeholder.com/300x200",
      total_fund_size: "INR 3 crores",
      participating_startups: 3,
      min_investment: "INR 6 lakhs",
      per_startup_allocation: "INR 2 lakhs",
      targeted_returns: "30",
      target_exit_period: "36-48",
      about:
        "Favcy Venture Builders is India's largest Venture builder, focusing on covering the startup's 0 to 1 journey. We have built 32+ startups in the last 4 years. Our mission is to democratize technology and support startups through their critical phases.",
      why_bts:
        "While building 32 ventures, we learned that not all founders want to build forever or build the same thing forever. First-time founders often lose steam while chasing VC funds, and investors seek quicker returns. Our 'Build to Sell' strategy addresses these needs with a clear exit plan from Day 0.",
      what: "At Favcy Venture Builders, we are creating a cohort of startups designed for acquisition within 36-48 months, targeting a minimum 30% IRR. These ventures are rigorously validated in coveted industries and co-built with founders from the ground up for exit via acquisition.",
      startup_details: [
        {
          name: "91 United",
          sector: "Gig economy",
          fund_size: "INR 75 lakhs",
          description:
            "A platform connecting gig workers with employers in the gig economy.",
        },
        {
          name: "RegalSutra",
          sector: "Wedding Industry",
          fund_size: "INR 1.5 crores",
          description: "A premium wedding planning and services platform.",
        },
        {
          name: "Sattva Halwai",
          sector: "Food and Beverages",
          fund_size: "INR 75 lakhs",
          description:
            "A traditional sweet and snacks brand expanding through online and retail channels.",
        },
      ],
      opportunity:
        "Investors have the opportunity to participate in a cohort of rigorously validated startups with a clear exit strategy. The focus is on industries with high growth potential, ensuring strong returns and a defined exit timeline.",
      team: "The team consists of experienced venture builders, startup mentors, and industry veterans who have successfully built and scaled multiple startups. Their combined expertise ensures that each startup in the cohort is poised for success.",
      faqs: "Q: What is the minimum investment required?\nA: The minimum investment required is INR 6 lakhs.\n\nQ: What is the targeted return on investment?\nA: We are targeting a minimum 30% IRR annualized.",
      process:
        "1. Review the investment opportunity.\n2. Express your interest by clicking 'I'm Interested'.\n3. Our team will reach out to you for further discussions and due diligence.\n4. Finalize your investment and start the journey with our 'Build to Sell' startups.",
    };

    setInvestment(dummyInvestment);
    setLoading(false);
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!investment) {
    return <div>No investment found.</div>;
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case "about":
        return <p>{investment.about}</p>;
      case "whyBTS":
        return <p>{investment.why_bts}</p>;
      case "what":
        return <p>{investment.what}</p>;
      case "startupDetails":
        return investment.startup_details.map((startup) => (
          <div key={startup.name} className="mb-3">
            <h4>{startup.name}</h4>
            <p>
              <strong>Sector:</strong> {startup.sector}
            </p>
            <p>
              <strong>Fund Size:</strong> {startup.fund_size}
            </p>
            <p>{startup.description}</p>
          </div>
        ));
      case "opportunity":
        return <p>{investment.opportunity}</p>;
      case "team":
        return <p>{investment.team}</p>;
      case "faqs":
        return <p style={{ whiteSpace: "pre-line" }}>{investment.faqs}</p>;
      case "process":
        return <p style={{ whiteSpace: "pre-line" }}>{investment.process}</p>;
      default:
        return null;
    }
  };

  return (
    <Container className="company-detail-page mt-4">
      <Row className="align-items-center mb-4">
        <Col xs={12} md={3}>
          <img
            src={investment.logo_url}
            alt={investment.name}
            className="company-logo img-fluid"
          />
        </Col>
        <Col xs={12} md={9}>
          <h1>{investment.name}</h1>
        </Col>
      </Row>

      <Card className="investment-overview mb-4">
        <Row>
          <Col xs={12} md={6}>
            <img
              src={investment.banner_url}
              alt="Investment Banner"
              className="banner-img img-fluid"
            />
          </Col>
          <Col xs={12} md={6}>
            <div className="overview-details p-3">
              <p>
                <strong>Total Fund Size:</strong> {investment.total_fund_size}
              </p>
              <p>
                <strong>Participating Startups:</strong>{" "}
                {investment.participating_startups}
              </p>
              <p>
                <strong>Minimum Investment Required:</strong>{" "}
                {investment.min_investment}
              </p>
              <p>
                <strong>Per Startup Allocation:</strong>{" "}
                {investment.per_startup_allocation}
              </p>
              <p>
                <strong>Targeted Returns:</strong> {investment.targeted_returns}
                % IRR
              </p>
              <p>
                <strong>Target Exit Period:</strong>{" "}
                {investment.target_exit_period} months
              </p>
            </div>
          </Col>
        </Row>
      </Card>

      <div className="custom-tabs">
        <ul className="tab-list">
          <li
            className={`tab-item ${activeTab === "about" ? "active" : ""}`}
            onClick={() => setActiveTab("about")}
          >
            About
          </li>
          <li
            className={`tab-item ${activeTab === "whyBTS" ? "active" : ""}`}
            onClick={() => setActiveTab("whyBTS")}
          >
            Why BTS
          </li>
          <li
            className={`tab-item ${activeTab === "what" ? "active" : ""}`}
            onClick={() => setActiveTab("what")}
          >
            What
          </li>
          <li
            className={`tab-item ${
              activeTab === "startupDetails" ? "active" : ""
            }`}
            onClick={() => setActiveTab("startupDetails")}
          >
            Startup Details
          </li>
          <li
            className={`tab-item ${
              activeTab === "opportunity" ? "active" : ""
            }`}
            onClick={() => setActiveTab("opportunity")}
          >
            Opportunity
          </li>
          <li
            className={`tab-item ${activeTab === "team" ? "active" : ""}`}
            onClick={() => setActiveTab("team")}
          >
            The Team
          </li>
          <li
            className={`tab-item ${activeTab === "faqs" ? "active" : ""}`}
            onClick={() => setActiveTab("faqs")}
          >
            FAQs
          </li>
          <li
            className={`tab-item ${activeTab === "process" ? "active" : ""}`}
            onClick={() => setActiveTab("process")}
          >
            Process (How)
          </li>
        </ul>

        <div className="tab-content">{renderTabContent()}</div>
      </div>

      <div className="fixed-buttons">
        <button className="me-2 interested-button">I'm Interested</button>
        <button className="shortlist-button">Shortlist</button>
      </div>
    </Container>
  );
}

export default CompanyName;
