import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ReactPaginate from "react-paginate";

function AngelInvestments() {
  const [selectedStages, setSelectedStages] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [filteredInvestments, setFilteredInvestments] = useState([]);
  const [investments, setInvestments] = useState([]);
  const [filteredInvestmentsCount, setFilteredInvestmentsCount] = useState(0);
  const [stages, setStages] = useState([]);
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Dummy data for angel investments
  const dummyInvestments = [
    {
      id: 1,
      name: "Startup One",
      stage: "Seed",
      type: "Individual Opportunity",
      min_investment: "INR 5 lakhs",
      targeted_returns: "25",
      time_frame: "36",
      logo_url: "https://img.icons8.com/fluency/48/startup.png",
    },
    {
      id: 2,
      name: "Startup Two",
      stage: "Series A",
      type: "Basket",
      min_investment: "INR 10 lakhs",
      targeted_returns: "30",
      time_frame: "48",
      logo_url: "https://img.icons8.com/fluency/48/startup.png",
    },
    {
      id: 3,
      name: "Startup Three",
      stage: "Pre-seed",
      type: "Individual Opportunity",
      min_investment: "INR 2 lakhs",
      targeted_returns: "20",
      time_frame: "24",
      logo_url: "https://img.icons8.com/fluency/48/startup.png",
    },
  ];

  useEffect(() => {
    // Simulate fetching data by setting dummy data
    setInvestments(dummyInvestments);
    setLoading(false);

    const stages = [...new Set(dummyInvestments.map((inv) => inv.stage))];
    const types = [...new Set(dummyInvestments.map((inv) => inv.type))];

    setStages(stages);
    setTypes(types);
  }, []);

  useEffect(() => {
    let filteredData = investments;

    if (selectedStages.length > 0) {
      filteredData = filteredData.filter((investment) =>
        selectedStages.includes(investment.stage)
      );
    }

    if (selectedTypes.length > 0) {
      filteredData = filteredData.filter((investment) =>
        selectedTypes.includes(investment.type)
      );
    }

    if (searchQuery) {
      filteredData = filteredData.filter((investment) =>
        investment.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredInvestments(filteredData);
    setFilteredInvestmentsCount(filteredData.length);
  }, [selectedStages, selectedTypes, investments, searchQuery]);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo(0, 0);
  };

  const handleCheckboxChange = useCallback((e, setSelectedState) => {
    const value = e.target.value;
    setSelectedState((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
  }, []);

  const clearAllFilters = () => {
    setSelectedStages([]);
    setSelectedTypes([]);
    setSearchQuery("");
  };

  const SidebarFilter = ({ title, options, selectedOptions, handleChange }) => {
    const [isOpen, setIsOpen] = useState(true);
    const [filterSearchQuery, setFilterSearchQuery] = useState("");

    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    const filteredOptions = options.filter((option) =>
      option.toLowerCase().includes(filterSearchQuery.toLowerCase())
    );

    return (
      <div className="filter-section">
        <h4 onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          {title}{" "}
          <span className="accordionCustomArrow">{isOpen ? "▲" : "▼"}</span>
        </h4>
        {isOpen && (
          <>
            <input
              type="text"
              placeholder={`Search ${title}`}
              value={filterSearchQuery}
              onChange={(e) => setFilterSearchQuery(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            {filteredOptions.map((option, index) => (
              <div key={index} className="filterName">
                <input
                  type="checkbox"
                  value={option}
                  checked={selectedOptions.includes(option)}
                  onChange={(e) => handleChange(e)}
                />
                <label>{option}</label>
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  const renderInvestments = useMemo(() => {
    const startIdx = currentPage * itemsPerPage;
    const investmentsToDisplay = filteredInvestments.slice(
      startIdx,
      startIdx + itemsPerPage
    );

    return investmentsToDisplay.map((investment) => (
      <div
        className="screener-list"
        key={investment.id}
        onClick={() => navigate(`/angel-investments/${investment.id}`)}
      >
        <div className="sceener-data">
          <div className="screener-info">
            <div className="d-flex">
              <div>
                <img
                  src={
                    investment.logo_url ||
                    "https://img.icons8.com/fluency/48/total-sales-1.png"
                  }
                  alt={investment.name}
                  style={{
                    width: "75px",
                    height: "auto",
                    marginRight: "10px",
                  }}
                />
              </div>
              <div className="d-flex flexCol">
                <span className={`stockName`}>{investment.name}</span>

                <div className="d-flex">
                  <p className="budget">
                    {investment.stage
                      .split("_")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </p>
                  <p className="budget">
                    {investment.type.charAt(0).toUpperCase() +
                      investment.type.slice(1)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-data">
          <div className="MY-data rate">
            <span className="value">{investment.min_investment}</span>
            <span>Min Investment</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{investment.targeted_returns}%</span>
            <span>Targeted Returns</span>
          </div>
          <div className="MY-data rate">
            <span className="value">{investment.time_frame} months</span>
            <span>Time Frame</span>
          </div>
        </div>
      </div>
    ));
  }, [currentPage, filteredInvestments, navigate]);

  return (
    <section className="angelInvestmentsListPage">
      <div className="filter-sidebar">
        <div
          style={{
            position: "sticky",
            top: "0",
            backgroundColor: "#fff",
            zIndex: "1",
          }}
        >
          <span className="spanText">Filters</span>
          <button onClick={clearAllFilters} className="clearAllButton">
            Clear All
          </button>
        </div>
        <SidebarFilter
          title="Stage"
          options={stages}
          selectedOptions={selectedStages}
          handleChange={(e) => handleCheckboxChange(e, setSelectedStages)}
        />
        <SidebarFilter
          title="Type"
          options={types}
          selectedOptions={selectedTypes}
          handleChange={(e) => handleCheckboxChange(e, setSelectedTypes)}
        />
      </div>
      <div className="fundsListAndSearch">
        {" "}
        <div className="search-container mt-3 mb-2">
          <input
            type="text"
            placeholder="Search angel investments..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <span className="main-title mfCounts">
            {filteredInvestmentsCount === 0
              ? "No Angel Investments Found"
              : `${filteredInvestmentsCount} Angel Investments Available`}
          </span>
        </div>
        <div className="funds-list">
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <Button style={{ backgroundColor: "#416052" }} disabled>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            </div>
          ) : (
            <>
              {renderInvestments}

              <div className="pagination-container">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(filteredInvestmentsCount / itemsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  initialPage={currentPage}
                  forcePage={currentPage}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default AngelInvestments;
