import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import AuthModal from "../authModule/authModal";
import userImg from "../../components/images/avatar-1.png";
import { useUserImage } from "../../components/context/userImageContext";
import { useAuth } from "../../components/context/authContext";
import OfferBanner from "../../components/offerBanner/offerBanner";
import "./navBar.css";
import Cookies from "js-cookie";

const Navbar = () => {
  const { imageUrl, updateImageUrl } = useUserImage();
  const { isLoggedIn, logout } = useAuth();
  const [loading, setLoading] = useState(true);
  const [navbarState, setNavbarState] = useState({
    isVisible: true,
    isShadowVisible: false,
    isOpen: false,
    prevScrollPos: window.scrollY,
  });
  const [showModal, setShowModal] = useState(false);
  const [authMode, setAuthMode] = useState("signin");
  const [intendedRoute, setIntendedRoute] = useState("/");
  const previousGeneratedUrl = useRef(imageUrl);
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    const tokenFromUrl = new URLSearchParams(window.location.search).get(
      "token"
    );
    if (tokenFromUrl) {
      Cookies.set("token", tokenFromUrl);
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    const token = Cookies.get("token");
    if (!token) {
      Cookies.remove("token");
    }
    setLoading(false);

    // Fetch the image URL from the cookie
    const cookieImageUrl = Cookies.get("profileImageUrl");
    if (cookieImageUrl) {
      updateImageUrl(cookieImageUrl);
    }
  }, [updateImageUrl]);

  useEffect(() => {
    if (isLoggedIn && imageUrl && imageUrl !== previousGeneratedUrl.current) {
      previousGeneratedUrl.current = imageUrl;
    }
  }, [imageUrl, isLoggedIn]);

  useEffect(() => {
    const bannerHiddenUntil = Cookies.get("bannerHiddenUntil");
    if (bannerHiddenUntil && new Date() < new Date(bannerHiddenUntil)) {
      setShowBanner(false);
    }

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setNavbarState((prevState) => ({
        ...prevState,
        isVisible:
          prevState.prevScrollPos > currentScrollPos || currentScrollPos < 50,
        isShadowVisible:
          currentScrollPos > 50 && prevState.prevScrollPos > currentScrollPos,
        prevScrollPos: currentScrollPos,
      }));
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (navbarState.isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [navbarState.isOpen]);

  const handleModalToggle = (mode, route = "/") => {
    setAuthMode(mode);
    setIntendedRoute(route);
    setShowModal(!showModal);
    setNavbarState((prevState) => ({ ...prevState, isOpen: false }));
  };

  const handleSignInSuccess = () => {
    setShowModal(false);
    navigate(intendedRoute);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  const handleBannerClose = () => {
    setShowBanner(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      handleBannerClose();
    }
  }, [isLoggedIn]);

  if (loading) {
    return null;
  }

  return (
    <div>
      {showBanner && <OfferBanner onClose={handleBannerClose} />}
      <nav
        className={`navbar ${navbarState.isVisible ? "visible" : "hidden"} ${
          navbarState.isShadowVisible ? "bottomShadow" : ""
        } ${!showBanner ? "without-banner" : ""}`}
      >
        <div className="container">
          <Link to="/">
            <img
              src="../images/logo.jpg"
              alt="Monarch Investmentd"
              className="logo"
            />
          </Link>
          <button
            className={`hamburger ${navbarState.isOpen ? "is-open" : ""}`}
            onClick={() =>
              setNavbarState((prevState) => ({
                ...prevState,
                isOpen: !prevState.isOpen,
              }))
            }
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className={`menu ${navbarState.isOpen ? "open" : ""}`}>
            <img
              src="./images/logo.jpg"
              alt="Monarch ivestments"
              className="logo-menu"
            />
            <Link
              to={isLoggedIn ? "/dashboard" : "/"}
              onClick={() =>
                setNavbarState((prevState) => ({ ...prevState, isOpen: false }))
              }
            >
              {" "}
              Home
            </Link>
            <Link
              to="/mutualFunds"
              onClick={() =>
                setNavbarState((prevState) => ({ ...prevState, isOpen: false }))
              }
            >
              Mutual Funds
            </Link>
            {/* <Link
              to="/angel-investments"
              onClick={() =>
                setNavbarState((prevState) => ({ ...prevState, isOpen: false }))
              }
            >
              Angel Investments <span className="newLabelTag mx-1">New</span>
            </Link> */}
            {/* <Link
              to="/successStories"
              onClick={() =>
                setNavbarState((prevState) => ({ ...prevState, isOpen: false }))
              }
            >
              Success Stories <span className="newLabelTag mx-1">New</span>
            </Link>
            <Link
              to="https://docs.google.com/forms/d/e/1FAIpQLSehgNLVLB4i7j9kr9pRMdWMdMuX30OfTq2HSrpadVZJt0Wkdg/viewform"
              onClick={() =>
                setNavbarState((prevState) => ({ ...prevState, isOpen: false }))
              }
            >
              NRI Tax Filing Webinar
              <span className="newLabelTag mx-1">New</span>
            </Link>
            <Link to="/tax-services">Tax Services</Link> */}
            {isLoggedIn ? (
              <>
                <Link
                  to="/portfolio"
                  onClick={() =>
                    setNavbarState((prevState) => ({
                      ...prevState,
                      isOpen: false,
                    }))
                  }
                >
                  My Portfolio
                </Link>
                <NavDropdown
                  menualign="right"
                  title={
                    <img
                      src={imageUrl || userImg}
                      alt="Profile"
                      className="d-inline-block align-top rounded-circle bg-white"
                      style={{
                        border: "2px solid #ccc",
                        height: "50px",
                        width: "50px",
                      }}
                    />
                  }
                  id="nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/my-account">
                    My Account
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <>
                <button
                  className="getStarted"
                  onClick={() => handleModalToggle("onboarding")}
                >
                  Get Started
                </button>
                <AuthModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                  mode={authMode}
                  onSignInSuccess={handleSignInSuccess}
                />
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
