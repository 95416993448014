import { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Portfolio from "./myPortfolio";
import KYCStatusAlert from "../../feature/kycStatusBanner/kycStatusBanner";

const trendingFundNames = [
  "Canara Robeco Bluechip Equity Fund",
  "HDFC Top 100 Fund",
  "Mirae Asset Large Cap Fund",
  "Edelweiss Mid Cap Fund",
  "PGIM India Midcap Opportunities Fund",
  "SBI Magnum Midcap Fund",
  "Nippon India Small Cap Fund",
  "SBI Small Cap Fund",
];

const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = useState(null);
  const [kycStatus, setKycStatus] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]); // State for companies
  const [trendingFundDetails, setTrendingFundDetails] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(`${baseUrl}/getUserData`, config);
        setUserData(response.data);
        setKycStatus(response.data.members?.KYCStatus);

        const memberId = response.data.members?.MemberId;
        if (memberId) {
          await fetchWishlist(memberId);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchWishlist = async (memberId) => {
      try {
        const token = Cookies.get("token");
        const config = {
          headers: {
            WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${baseUrl}/wishlist/${memberId}`,
          config
        );
        setWishlist(response.data);
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcategories`);
        setCategories(response.data.slice(0, 4));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/mfcompanies`);
        setCompanies(response.data.slice(0, 4));
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    // const fetchTrendingFundDetails = async () => {
    //   try {
    //     const response = await axios.get(`${baseUrl}/api/mutualfunds`);
    //     console.log("All mutual funds:", response.data.mutualFunds);
    //     const allMutualFunds = response.data.mutualFunds;

    //     // Find trending funds by matching names
    //     const trendingFunds = allMutualFunds.filter((fund) =>
    //       trendingFundNames.includes(fund.name)
    //     );
    //     console.log("Trending funds:", trendingFunds);

    //     // Fetch detailed information for each trending fund
    //     const detailsResponses = await Promise.all(
    //       trendingFunds.map((fund) =>
    //         axios.get(`${baseUrl}/funds-details/${fund.id}`)
    //       )
    //     );
    //     console.log("Trending fund details responses:", detailsResponses);

    //     const details = detailsResponses.map(
    //       (response) => response.data.fundDetails
    //     );
    //     console.log("Trending fund details:", details);
    //     setTrendingFundDetails(details);
    //   } catch (error) {
    //     console.error("Error fetching trending fund details:", error);
    //   }
    // };

    fetchUserData();
    fetchCategories();
    fetchCompanies();
    // fetchTrendingFundDetails();
  }, [baseUrl]);

  return (
    <Container className="dashboardContainer">
      <KYCStatusAlert kycStatus={kycStatus} />

      <div className="d-flex">
        <Col md={8} className="mainContent m-2">
          <h2>Welcome.</h2>
          <div className="dashBoardExploreFunds">
            <h4 className="dashboardSecondaryHeadings">Explore funds</h4>
            <div className="flexWrap">
              {categories.length > 0 ? (
                categories.map((category) => (
                  <Link
                    to={`/mutualFunds?category=${encodeURIComponent(
                      category.category
                    )}`}
                    key={category.id}
                    className="exploreFundLink"
                  >
                    <span className="exploreFundLinkContent">
                      <span>{category.category}</span>
                    </span>
                  </Link>
                ))
              ) : (
                <span>Loading categories...</span>
              )}
            </div>
          </div>

          <div className="dashBoardExploreFunds">
            <h4 className="dashboardSecondaryHeadings">Explore AMC</h4>
            <div className="flexWrap">
              {companies.length > 0 ? (
                companies.map((company) => (
                  <Link
                    to={`/mutualFunds?company=${encodeURIComponent(
                      company.id
                    )}`}
                    key={company.id}
                    className="exploreFundLink"
                  >
                    <span className="exploreFundLinkContent">
                      <span>{company.name}</span>
                    </span>
                  </Link>
                ))
              ) : (
                <span>Loading companies...</span>
              )}
            </div>
          </div>

          {/* <div className="dashBoardExploreFunds">
            <h4 className="dashboardSecondaryHeadings">Trending Funds</h4>
            <Row>
              {trendingFundDetails.length > 0 ? (
                trendingFundDetails.map((fund) => (
                  <Col md={6} key={fund.id} className="trendingFundCard">
                    <Link to={`/mutualFunds-detail/${fund.id}`}>
                      <div className="fundCard">
                        <h5>{fund.name}</h5>
                        <p>1Y Return: {fund["DP-Return1Yr"] ?? "N/A"}%</p>
                        <p>5Y Return: {fund["DP-Return5Yr"] ?? "N/A"}%</p>
                        <p>10Y Return: {fund["DP-Return10Yr"] ?? "N/A"}%</p>
                      </div>
                    </Link>
                  </Col>
                ))
              ) : (
                <p>Loading trending funds...</p>
              )}
            </Row>
          </div> */}

          <div>
            <h4 className="dashboardSecondaryHeadings">Quick Links</h4>
            <div className="dashBoardQuickLinks">
              <Link to={`/calculators`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/isometric/32/calculator.png"
                      alt="Calculator"
                    />
                  </span>
                  <span>Explore our calculators</span>
                </span>
              </Link>
              <Link to={`/blogs`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/arcade/32/signing-a-document.png"
                      alt="blogs"
                    />
                  </span>
                  <span>Blogs</span>
                </span>
              </Link>
              <Link to={`/compare-funds`} className="exploreFundLink">
                <span className="exploreFundLinkContent">
                  <span>
                    <img
                      src="https://img.icons8.com/external-flaticons-flat-flat-icons/32/external-compare-medical-ecommerce-flaticons-flat-flat-icons.png"
                      alt="compare funds"
                    />
                  </span>
                  <span>Compare Funds</span>
                </span>
              </Link>
            </div>
          </div>
        </Col>
        <Col md={4} className="trendingSection m-2">
          <div className="trendingContent">
            <Portfolio />
          </div>
          <div className="myWishList">
            <div className="wishlistHeader">
              <span>Wishlist {wishlist.length} items</span>
            </div>
            <div className="wishListContainer">
              <div className="wishListItemHeader">
                <span>Fund Name</span>
                {/* <span>1D returns</span> */}
              </div>
              {wishlist.length > 0
                ? wishlist.map((item) => (
                    <div key={item.fundId} className="wishListItem">
                      <Link
                        to={`/mutualFunds-detail/${item.fundId}`}
                        className="wishListItemLink"
                      >
                        <div className="wishListItemContent">
                          <span>{item.fundName}</span>
                          {/* <span
                              className={
                                item.change > 0 ? "text-success" : "text-danger"
                              }
                            >
                              {item.change > 0 ? "+" : ""}
                              {item.change} ({item.percentageChange}%)
                            </span> */}
                        </div>
                      </Link>
                    </div>
                  ))
                : "Nothing in wishlist yet."}
            </div>
          </div>
        </Col>
      </div>
    </Container>
  );
};

export default Dashboard;
