import "./investEquity.css";
import { Row, Col } from "react-bootstrap";
import OtherAnimation from "../../components/animations/globe-color-change";
import Lottie from "lottie-react";

const InvestInEquity = () => {
  const OtherBannerAnimation = {
    loop: true,
    autoplay: true,
    animationData: OtherAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <section className="investEquity">
      <Row className="equity-data">
        <Col className="equity-content-col">
          <h1 className="secondary-heading">Invest in U.S. Equities</h1>
          <p className="secondary-content">
            USA’s Gross Domestic Product is almost 10 times that of India. That
            means you are participating in a much larger market with US-listed
            companies accounting for almost 35% – 40% of the world’s market
            capitalization.
          </p>
          <button class="button dark  border-animation" id="loginButton">
            <span>Start Investing</span>
          </button>
        </Col>
        <Col className="equity-animation-col">
          <Lottie
            animationData={OtherBannerAnimation.animationData}
            loop={true}
            height={60}
            width={60}
            className="animationGlobe"
          />
        </Col>
      </Row>
    </section>
  );
};

export default InvestInEquity;
