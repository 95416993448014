import { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import parse from "html-react-parser";

const AmInvestAccordion = ({ type, data }) => {
  const [activeKey, setActiveKey] = useState(null);

  const handleSelect = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  const renderFAQs = (data) => {
    return data.map((item, index) => {
      const currentEventKey = index.toString();
      const isActive = activeKey === currentEventKey;
      return (
        <Card
          key={index}
          className="mb-3"
          style={{ color: "#063d4d", background: "#ECF3F0" }}
        >
          <Accordion.Header
            onClick={() => handleSelect(currentEventKey)}
            eventKey={currentEventKey}
          >
            {item.question}
            <span className={`indicator ${isActive ? "active" : "inactive"}`}>
              {isActive ? "-" : "+"}
            </span>
          </Accordion.Header>
          <Accordion.Collapse eventKey={currentEventKey}>
            <Card.Body style={{ fontWeight: "600" }}>
              {parse(item.answer)}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      );
    });
  };

  return (
    <Accordion activeKey={activeKey}>
      {type === "faq" ? (
        renderFAQs(data)
      ) : (
        <div>No content available for the selected type</div>
      )}
    </Accordion>
  );
};

export default AmInvestAccordion;
