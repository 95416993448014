import { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Breadcrumb,
  Spinner,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Chart from "chart.js/auto";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { FaRegBookmark, FaBookmark, FaShareAlt } from "react-icons/fa";
import useUserData from "../useEffect/useUserData";
import ReturnCalculator from "../../feature/calculators/fundsReturn";
axios.defaults.withCredentials = true;

function MutualFunds() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  const [mode, setMode] = useState("lumpsum");
  const [fundDetails, setFundDetails] = useState(null);
  const [trustees, setTrustees] = useState([]);
  const [returnsData, setReturnsData] = useState(null);
  const [ratio, setRatio] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("6M");
  const [percentageChange, setPercentageChange] = useState(null);
  const chartRef = useRef(null);
  const [OneTimeAmount, setOneTimeAmount] = useState("");
  const [SIPAmount, setSIPAmount] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [wishlist, setWishlist] = useState([]);
  const [isWishlisted, setIsWishlisted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { userData } = useUserData();
  const [isWishlistEnabled, setIsWishlistEnabled] = useState(false);
  const [fundDetailsLoading, setFundDetailsLoading] = useState(true);
  const [wishlistLoading, setWishlistLoading] = useState(true);
  const shareUrl = window.location.href;
  const [logoUrl, setLogoUrl] = useState(null);

  const handleOneTimeAmountChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setOneTimeAmount(value);
    }
  };

  const handleSIPAmountChange = (event) => {
    const { value } = event.target;
    if (/^\d{0,10}$/.test(value)) {
      setSIPAmount(value);
    }
  };
  useEffect(() => {
    const fetchLogo = async () => {
      if (id) {
        try {
          const response = await axios.get(`${baseUrl}/fund-logo/${id}`, {
            withCredentials: true,
          });
          setLogoUrl(response.data.url);
        } catch (error) {
          console.error(`Error fetching logo for fund ${id}:`, error);
          setLogoUrl("https://img.icons8.com/fluency/48/total-sales-1.png");
        }
      }
    };

    fetchLogo();
  }, [id, baseUrl]);
  useEffect(() => {
    const fetchNAVHistory = async () => {
      if (id) {
        try {
          const response = await axios.get(`${baseUrl}/new_nav_history/${id}`);
          if (response.data && response.data.length > 0) {
            setData(response.data);
          } else {
            console.error("No NAV history data found.");
          }
          setLoading(false);
        } catch (error) {
          console.error("Error fetching NAV history data:", error);
          setLoading(false);
        }
      }
    };

    const fetchFundDetails = async () => {
      if (id) {
        setFundDetailsLoading(true);
        try {
          const response = await axios.get(`${baseUrl}/funds-details/${id}`);
          setFundDetails(response.data.fundDetails);
          setTrustees(response.data.trustees);
          setReturnsData(response.data.returns);
          setRatio(response.data.ratio);
        } catch (error) {
          console.error("Error fetching fund details:", error);
        } finally {
          setFundDetailsLoading(false);
        }
      }
    };

    const checkLoginState = () => {
      const token = Cookies.get("token");
      if (token) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    const fetchAllData = async () => {
      await Promise.all([fetchFundDetails(), fetchNAVHistory()]);
      checkLoginState();
    };

    fetchAllData();
  }, [id, baseUrl, isLoggedIn, userData]);

  useEffect(() => {
    const fetchWishlist = async () => {
      if (isLoggedIn) {
        setWishlistLoading(true);
        try {
          const userId = userData?.memberProfile?.MemberId;
          if (userId) {
            const token = Cookies.get("token");
            const config = {
              headers: {
                WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
              },
            };
            const response = await axios.get(
              `${baseUrl}/wishlist/${userId}`,
              config
            );
            setWishlist(response.data);
            setIsWishlisted(response.data.some((item) => item.fundId === id));
            setIsWishlistEnabled(true);
          }
        } catch (error) {
          console.error("Error fetching wishlist:", error);
          toast.error("Failed to fetch wishlist.");
        } finally {
          setWishlistLoading(false);
        }
      }
    };

    fetchWishlist();
  }, [id, isLoggedIn, userData, baseUrl]);
  const handleWishlistToggle = async () => {
    try {
      const userId = userData?.memberProfile?.MemberId;
      if (!userId) {
        toast.error("Please log in to manage your wishlist.");
        return;
      }

      const token = Cookies.get("token");
      if (!token) {
        toast.error("Authentication token not found. Please log in.");
        return;
      }

      const config = {
        headers: {
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
      };

      const isCurrentlyWishlisted = wishlist.some((item) => item.fundId === id);

      let response;
      if (isCurrentlyWishlisted) {
        // Remove from wishlist
        response = await axios.delete(
          `${baseUrl}/wishlist/${userId}/${id}`,
          config
        );
      } else {
        // Add to wishlist
        response = await axios.post(
          `${baseUrl}/wishlist`,
          {
            memberId: userId,
            fundId: id,
            fundName: fundDetails.name,
          },
          config
        );
      }

      if (response.status === 200) {
        const updatedWishlist = isCurrentlyWishlisted
          ? wishlist.filter((item) => item.fundId !== id)
          : [...wishlist, { fundId: id, fundName: fundDetails.name }];

        setWishlist(updatedWishlist);
        setIsWishlisted(!isCurrentlyWishlisted);
        toast.success(
          isCurrentlyWishlisted
            ? "Removed from wishlist."
            : "Added to wishlist."
        );
      } else {
        toast.error("Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("Failed to update wishlist.");
    }
  };

  const calculatePercentageChange = (timePeriod, navData) => {
    timePeriod = timePeriod || "1Y";
    const filteredData = filterDataByTimePeriod(navData, timePeriod);
    if (filteredData.length > 0) {
      const navEnd = filteredData[0].nav;
      const navStart = filteredData[filteredData.length - 1].nav;
      const change = ((navEnd - navStart) / navStart) * 100;
      setPercentageChange(change.toFixed(2));
    } else {
      setPercentageChange(null);
    }
  };

  const filterDataByTimePeriod = (data, timePeriod) => {
    const currentDate = new Date();
    return data.filter((item) => {
      const date = new Date(item.date);
      switch (timePeriod) {
        case "1M":
          return (
            date >=
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - 1,
              currentDate.getDate()
            )
          );
        case "6M":
          return (
            date >=
            new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - 6,
              currentDate.getDate()
            )
          );
        case "1Y":
          return (
            date >=
            new Date(
              currentDate.getFullYear() - 1,
              currentDate.getMonth(),
              currentDate.getDate()
            )
          );
        case "3Y":
          return (
            date >=
            new Date(
              currentDate.getFullYear() - 3,
              currentDate.getMonth(),
              currentDate.getDate()
            )
          );
        default:
          return true;
      }
    });
  };

  useEffect(() => {
    if (data && data.length > 0) {
      renderChart(data);
      calculatePercentageChange(selectedTimePeriod, data);
    }
  }, [data, selectedTimePeriod]);

  const handleTimePeriodChange = (period) => {
    setSelectedTimePeriod(period);
    calculatePercentageChange(period, data);
  };

  const renderChart = (navData) => {
    if (chartRef.current !== null) {
      chartRef.current.destroy();
    }
    const filteredData = filterDataByTimePeriod(navData, selectedTimePeriod);

    const dates = filteredData
      .map((item) => {
        const date = new Date(item.date);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      })
      .reverse();

    const navs = filteredData.map((item) => item.nav).reverse();

    const ctx = document.getElementById("mutualFundChart").getContext("2d");

    Chart.register({
      id: "verticalDottedLine",
      afterDraw: function (chart, args, options) {
        var activePoints = chart.tooltip.getActiveElements();
        if (activePoints.length > 0) {
          var activePoint = activePoints[0];
          var ctx = chart.ctx;
          var x = activePoint.element.x;

          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([5, 5]);
          ctx.moveTo(x, 0);
          ctx.lineTo(x, chart.height);
          ctx.strokeStyle = "rgba(0, 0, 0, 0.5)";
          ctx.stroke();
          ctx.restore();
        }
      },
    });

    chartRef.current = new Chart(ctx, {
      type: "line",
      data: {
        labels: dates,
        datasets: [
          {
            label: "NAV",
            data: navs,
            borderColor: "#063d4d",
            color: "#063d4d",
            borderWidth: 2,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        scales: {
          x: {
            display: false, // Hides x-axis labels
            grid: {
              display: false, // Hides x-axis grid lines
            },
          },
          y: {
            display: false, // Hides y-axis labels
            grid: {
              display: false, // Hides y-axis grid lines
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            backgroundColor: "#063d4d",
            enabled: true,
          },
          verticalDottedLine: {},
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        elements: {
          line: {
            tension: 0,
          },
        },
        backgroundColor: "transparent",
        interaction: {
          mode: "index",
          intersect: false,
        },
      },
    });
  };

  const formatXAxisDates = (filteredData, selectedTimePeriod) => {
    return filteredData
      .map((item) => {
        const date = new Date(item.date);
        let formattedDate;
        if (selectedTimePeriod === "1M" || selectedTimePeriod === "6M") {
          formattedDate = `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`;
        } else {
          formattedDate = date.getFullYear().toString();
        }
        return formattedDate;
      })
      .reverse()
      .map(String);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const [isKYCNotCompleted, setKYCStatus] = useState(false);

  useEffect(() => {
    const KYCStatus = localStorage.getItem("KYCStatus");
    if (KYCStatus === "pending" || "inprogress") {
      setKYCStatus(true);
    }
  });

  const handleClickInvest = async () => {
    try {
      if (
        (mode === "lumpsum" &&
          (OneTimeAmount === null ||
            OneTimeAmount === "" ||
            parseFloat(OneTimeAmount) === 0)) ||
        (mode === "sip" &&
          (SIPAmount === null ||
            SIPAmount === "" ||
            parseFloat(SIPAmount) === 0))
      ) {
        toast.error("Amount cannot be null or zero");
        return;
      }
      const requestData = {
        mode,
        amount: mode === "lumpsum" ? OneTimeAmount : SIPAmount,
        fund_id: id,
      };
      const token = Cookies.get("token");

      const response = await fetch(`${baseUrl}/transactions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          WRGSJGEUWCQN58EGV92D: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        console.error("Failed to invest");
      }

      const responseData = await response.json();
      if (responseData.success) {
        toast.success("Order has been successfully placed.");
      } else {
        toast.error("Order declined.");
      }
    } catch (error) {
      console.error("Investment failed:", error.message);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Container className="my-5 py-5">
        <Breadcrumb className="my-3">
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/mutualFunds">Mutual Funds</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Mutual Funds Details</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="screener-list mutualfund-detail">
                  <div className="sceener-data">
                    <div className="screener-info">
                      <img
                        src={
                          logoUrl ||
                          "https://img.icons8.com/fluency/48/total-sales-1.png"
                        }
                        alt={fundDetails?.name}
                        style={{
                          width: "75px",
                          height: "auto",
                          marginRight: "10px",
                        }}
                      />

                      <h3>{fundDetails?.name ?? "Loading..."}</h3>
                      <div className="fundsTypeAndShare">
                        <div className="fundsType">
                          <Link to="" className="budget">
                            {fundDetails?.risk
                              ?.split("_")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")}
                          </Link>
                        </div>
                        <div className="fundsShare">
                          {isLoggedIn ? (
                            <Button
                              type="button"
                              className="wishListButton"
                              onClick={handleWishlistToggle}
                              disabled={wishlistLoading || fundDetailsLoading}
                              style={{
                                background: isWishlisted ? "#063d4d" : "#ccc",
                                opacity:
                                  wishlistLoading || fundDetailsLoading
                                    ? 0.5
                                    : 1,
                              }}
                            >
                              {isWishlisted ? (
                                <FaBookmark size={20} color="#fff" />
                              ) : (
                                <FaRegBookmark size={20} color="#000" />
                              )}
                            </Button>
                          ) : (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-disabled">
                                  Please sign-in to add to wishlist
                                </Tooltip>
                              }
                            >
                              <span className="d-inline-block">
                                <Button
                                  type="button"
                                  className="wishListButton"
                                  disabled
                                  style={{
                                    background: isWishlisted
                                      ? "#063d4d"
                                      : "#ccc",
                                    opacity:
                                      wishlistLoading || fundDetailsLoading
                                        ? 0.5
                                        : 1,
                                  }}
                                >
                                  {isWishlisted ? (
                                    <FaBookmark size={20} />
                                  ) : (
                                    <FaRegBookmark size={20} />
                                  )}
                                </Button>
                              </span>
                            </OverlayTrigger>
                          )}
                          <Button
                            type="button"
                            className="shareButton"
                            onClick={toggleModal}
                          >
                            <FaShareAlt size={20} />
                          </Button>
                          <Modal show={isModalOpen} onHide={toggleModal}>
                            <Modal.Header closeButton>
                              <Modal.Title>Share</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="d-flex justify-content-around">
                                <FacebookShareButton url={shareUrl}>
                                  <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <TwitterShareButton url={shareUrl}>
                                  <TwitterIcon size={32} round />
                                </TwitterShareButton>
                                <WhatsappShareButton url={shareUrl}>
                                  <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                                <LinkedinShareButton url={shareUrl}>
                                  <LinkedinIcon size={32} round />
                                </LinkedinShareButton>
                                <RedditShareButton url={shareUrl}>
                                  <RedditIcon size={32} round />
                                </RedditShareButton>
                                <TelegramShareButton url={shareUrl}>
                                  <TelegramIcon size={32} round />
                                </TelegramShareButton>
                                <EmailShareButton url={shareUrl}>
                                  <EmailIcon size={32} round />
                                </EmailShareButton>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/* Chart Container */}
            <Row>
              <Col lg={12}>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Button style={{ backgroundColor: "#416052" }} disabled>
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </Button>
                    </div>
                  ) : (
                    <>
                      {percentageChange !== null ? (
                        <div
                          className="percentage"
                          style={{
                            color: percentageChange < 0 ? "red" : "#063d4d",
                          }}
                        >
                          <span style={{ fontSize: "25px" }}>
                            {percentageChange}%
                          </span>{" "}
                          &nbsp;
                          <span>{selectedTimePeriod} Return</span>
                        </div>
                      ) : null}
                      <canvas id="mutualFundChart"></canvas>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="btn periodTabTitle"
                          style={{
                            backgroundColor:
                              selectedTimePeriod === "1M" ? "#063d4d" : "",
                            color: selectedTimePeriod === "1M" ? "#fff" : "",
                          }}
                          onClick={() => handleTimePeriodChange("1M")}
                        >
                          1M
                        </button>
                        <button
                          className="btn periodTabTitle"
                          style={{
                            backgroundColor:
                              selectedTimePeriod === "6M" ? "#063d4d" : "",
                            color: selectedTimePeriod === "6M" ? "#fff" : "",
                          }}
                          onClick={() => handleTimePeriodChange("6M")}
                        >
                          6M
                        </button>
                        <button
                          className="btn periodTabTitle"
                          style={{
                            backgroundColor:
                              selectedTimePeriod === "1Y" ? "#063d4d" : "",
                            color: selectedTimePeriod === "1Y" ? "#fff" : "",
                          }}
                          onClick={() => handleTimePeriodChange("1Y")}
                        >
                          1Y
                        </button>
                        <button
                          className="btn periodTabTitle"
                          style={{
                            backgroundColor:
                              selectedTimePeriod === "3Y" ? "#063d4d" : "",
                            color: selectedTimePeriod === "3Y" ? "#fff" : "",
                          }}
                          onClick={() => handleTimePeriodChange("3Y")}
                        >
                          3Y
                        </button>
                        <button
                          className="btn periodTabTitle"
                          style={{
                            backgroundColor:
                              selectedTimePeriod === "ALL" ? "#063d4d" : "",
                            color: selectedTimePeriod === "ALL" ? "#fff" : "",
                          }}
                          onClick={() => handleTimePeriodChange("ALL")}
                        >
                          ALL
                        </button>
                      </div>
                      <hr />
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-2 pt-2">
              <Row className="mt-3 fundsDetailsRow">
                <Col lg={3}>
                  <div className="data">
                    <div className={"detailsOf"}>1Y Return</div>
                    <div className="value">
                      {returnsData?.["DP-Return1Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="data">
                    <div className={"detailsOf"}>3Y Return</div>
                    <div className="value">
                      {returnsData?.["DP-Return3Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="data">
                    <div className={"detailsOf"}>5Y Return</div>
                    <div className="value">
                      {returnsData?.["DP-Return5Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="data">
                    <div className={"detailsOf"}>Expense Ratio</div>
                    <div className="value">
                      {ratio?.["ARF-InterimNetExpenseRatio"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3 fundsDetailsRow">
                <Col lg={12} className="fundsDetailsCol">
                  <h5 className="sectionTitle">Return Calculator</h5>
                  <hr className="customHr" />
                </Col>
                <ReturnCalculator returnsData={returnsData} />
              </Row>

              <Col lg={12} className="fundsDetailsCol">
                <h5 className="sectionTitle">Returns</h5>
                <hr className="customHr" />
              </Col>

              <Row className="mt-3 fundsDetailsRow ">
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>1 Day</div>
                    <div className="value">
                      {returnsData?.["DP-Return1Day"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>1 Week</div>
                    <div className="value">
                      {returnsData?.["DP-Return1Week"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>1 Month</div>
                    <div className="value">
                      {returnsData?.["DP-Return1Mth"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>3 Month</div>
                    <div className="value">
                      {returnsData?.["DP-Return3Mth"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>6 Month</div>
                    <div className="value">
                      {returnsData?.["DP-Return6Mth"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>1 Year</div>
                    <div className="value">
                      {returnsData?.["DP-Return1Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>3 Year</div>
                    <div className="value">
                      {returnsData?.["DP-Return3Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>5 Year</div>
                    <div className="value">
                      {returnsData?.["DP-Return5Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
              </Row>

              <Col lg={12} className="fundsDetailsCol">
                <h5 className="sectionTitle">Cumulative Returns</h5>
                <hr className="customHr" />
              </Col>

              <Row className="mt-3 fundsDetailsRow">
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>3 Year</div>
                    <div className="value">
                      {returnsData?.["TTR-CumulativeReturn3Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>4 Year </div>
                    <div className="value">
                      {returnsData?.["TTR-CumulativeReturn4Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>5 Year</div>
                    <div className="value">
                      {returnsData?.["DP-CumulativeReturn5Yr"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
              </Row>
              <Col lg={12} className="fundsDetailsCol">
                <h5 className="sectionTitle">Expense Ratio</h5>
                <hr className="customHr" />
              </Col>

              <Row className="mt-3 fundsDetailsRow">
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>Interim Net Expense Ratio</div>
                    <div className="value">
                      {ratio?.["ARF-InterimNetExpenseRatio"] ?? "N/A"}%
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>Expense Ratio Date</div>
                    <div className="value">
                      {ratio?.["ARF-InterimNetExpenseRatioDate"]
                        ? new Date(
                            ratio["ARF-InterimNetExpenseRatioDate"]
                          ).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          })
                        : "N/A"}
                    </div>
                  </div>
                </Col>
              </Row>

              <Col lg={12} className="fundsDetailsCol">
                <h5 className="sectionTitle">Dividend Details</h5>
                <hr className="customHr" />
              </Col>

              <Row className="mt-3 fundsDetailsRow">
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>Dividend</div>
                    <div className="value">
                      {returnsData?.["DP-Dividend"] ?? "N/A"}
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="data">
                    <div className={"detailsOf"}>Dividend Date</div>
                    <div className="value">
                      {returnsData?.["DP-DividendDate"]
                        ? new Date(
                            returnsData["DP-DividendDate"]
                          ).toLocaleString("en-IN", {
                            timeZone: "Asia/Kolkata",
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                          })
                        : "N/A"}
                    </div>
                  </div>
                </Col>
              </Row>

              {trustees.length > 0 && (
                <>
                  <Col lg={12} className="fundsDetailsCol">
                    <h5 className="sectionTitle">Trustees</h5>
                    <hr className="customHr" />
                  </Col>
                  <Row className="mt-3 fundsDetailsRow">
                    {trustees.map((trustee) => (
                      <Col lg={6} key={trustee._id}>
                        <div className="mb-3 trusteeCard">
                          <h3>{trustee.CompanyName}</h3>
                          <div>
                            {trustee.CompanyAddress}
                            {trustee.CompanyCity} {trustee.CompanyPostalCode}{" "}
                            {trustee.CompanyProvince} {trustee.CompanyCountry}
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Row>
          </Col>
          {/* <Col lg={4} className="investAndCalc">
            <div>
              {!isLoggedIn && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "1",
                  }}
                >
                  <Link
                    to="/sign-in"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <Button style={{ backgroundColor: "#416052" }}>
                      Login to Invest Now
                    </Button>
                  </Link>
                </div>
              )}

              {isLoggedIn && isKYCNotCompleted && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: "1",
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <Link
                    to="/profile-process#basic-profile"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button style={{ backgroundColor: "#416052" }}>
                      Please click here to complete KYC.
                    </Button>
                  </Link>
                </div>
              )}

              <div>
                <Card
                  style={{
                    height: "300px",
                    overflowY: "auto",
                    filter: isLoggedIn ? "none" : "blur(5px)",
                    opacity: isLoggedIn ? "1" : "0.9",
                  }}
                >
                  <Card.Body
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className="d-flex justify-content-between m-0 p-0">
                        <Button
                          className="btn  w-100 m-0"
                          onClick={() => setMode("lumpsum")}
                          variant={mode === "lumpsum" ? "primary" : "secondary"}
                          style={{
                            marginLeft: "20px",
                            backgroundColor:
                              mode === "lumpsum" ? "#497664" : "",
                            color: mode === "lumpsum" ? "#fff" : "",
                          }}
                        >
                          One-Time
                        </Button>
                        <Button
                          className="btn  w-100 m-0 ms-1"
                          onClick={() => setMode("sip")}
                          variant={mode === "sip" ? "primary" : "secondary"}
                          style={{
                            marginRight: "20px",
                            backgroundColor: mode === "sip" ? "#497664" : "",
                            color: mode === "sip" ? "#fff" : "",
                          }}
                        >
                          SIP
                        </Button>
                      </div>
                      {mode === "lumpsum" && (
                        <div>
                          <br></br>
                          <Form.Group controlId="oneTimeAmount">
                            <Form.Label>Amount (₹)</Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={10}
                              value={OneTimeAmount}
                              onChange={handleOneTimeAmountChange}
                              placeholder="Enter amount"
                            />
                          </Form.Group>
                        </div>
                      )}
                      {mode === "sip" && (
                        <div>
                          <br></br>
                          <Form.Group controlId="monthlySipAmount">
                            <Form.Label>SIP Amount (₹) </Form.Label>
                            <Form.Control
                              type="text"
                              maxLength={10}
                              value={SIPAmount}
                              onChange={handleSIPAmountChange}
                              placeholder="Enter amount"
                            />
                          </Form.Group>
                        </div>
                      )}
                    </div>
                    {(mode === "lumpsum" || mode === "sip") && (
                      <>
                        <Button
                          type="button"
                          className="btn"
                          style={{ backgroundColor: "#497664", color: "#fff" }}
                          onClick={handleClickInvest}
                        >
                          Invest
                        </Button>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

export default MutualFunds;
