import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AmInvestButtons from "../../components/buttons/amInvestButton";
import AuthModal from "../authModule/authModal";
import "./bannerSection.css";
import Lottie from "lottie-react";
import DesktopAnimation from "../../components/animations/bannerAnimation.json";
import MobileAnimation from "../../components/animations/animMobile.json";
import Cookies from "js-cookie";

const AmInvestBanner = () => {
  useEffect(() => {
    const setCustomVh = () => {
      const userAgentString = navigator.userAgent.toLowerCase();

      const chromeAgent = userAgentString.indexOf("chrome") > -1;

      // Detect Internet Explorer

      let safariAgent = userAgentString.indexOf("safari") > -1;

      if (chromeAgent && safariAgent) {
        safariAgent = false;
      }

      if (safariAgent) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh-safari", `${vh}px`);
      } else {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
    };

    setCustomVh();

    window.addEventListener("resize", setCustomVh);
    window.addEventListener("orientationchange", setCustomVh);

    return () => {
      window.removeEventListener("resize", setCustomVh);
      window.removeEventListener("orientationchange", setCustomVh);
    };
  }, []);

  const desktopBannerAnimation = {
    loop: true,
    autoplay: true,
    animationData: DesktopAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const mobileBannerAnimation = {
    loop: true,
    autoplay: true,
    animationData: MobileAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [showModal, setShowModal] = useState(false);
  const [authMode, setAuthMode] = useState("signin");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get("token");
    setIsLoggedIn(!!token);
  }, []);

  const handleOpenModal = (mode) => {
    setAuthMode(mode);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSignInSuccess = () => {
    setShowModal(false);
    setIsLoggedIn(true);
    navigate("/dashboard");
  };
  const userAgentString = navigator.userAgent.toLowerCase();

  const chromeAgent = userAgentString.indexOf("chrome") > -1;

  let safariAgent = userAgentString.indexOf("safari") > -1;

  if (chromeAgent && safariAgent) {
    safariAgent = false;
  }

  return (
    <>
      <section className={`bannerSection`}>
        <div className="container bannerContentSection">
          <div className="caption">
            <h1 className="mainBannerHeading gradient-text">
              Wealthcare Redefined <br />
            </h1>
            <div className="subHeading my-2">
              <p className="p-0 lh-130 text-center">
                It's Time to start redefining wealth in your life and we are the
                best place to start
              </p>
            </div>
            <div className="bannerButton">
              {isLoggedIn ? (
                <Link
                  to="/dashboard"
                  className="goToProfileButton"
                  id="profileButton"
                >
                  Go to Dashboard
                </Link>
              ) : (
                <AmInvestButtons
                  title="Start Investing"
                  buttonType="dark"
                  onClick={() => handleOpenModal("signin")}
                  id="loginButton"
                />
              )}
            </div>
          </div>
        </div>
        <Lottie
          animationData={desktopBannerAnimation.animationData}
          loop={true}
          height={60}
          width={60}
          className="bannerAnimationDesktop"
        />
        {/*
        <Lottie
          animationData={mobileBannerAnimation.animationData}
          loop={true}
          height={60}
          width={60}
          className="bannerAnimationMobile"
        /> */}
        <AuthModal
          show={showModal}
          onHide={handleCloseModal}
          mode={authMode}
          onSignInSuccess={handleSignInSuccess}
        />
      </section>
    </>
  );
};

export default AmInvestBanner;
