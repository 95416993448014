import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./newsSection.css";

const NewsSection = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleNewsCount, setVisibleNewsCount] = useState(10);

  useEffect(() => {
    axios
      .get(`${baseUrl}/news`)
      // .get(`/news`)
      .then((response) => {
        setNews(response.data.news);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching news data:", error));
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ...";
    }
    return description;
  };
  const truncateTitle = (title, wordLimit) => {
    const words = title.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + " ...";
    }
    return title;
  };

  const renderNews = () => {
    return news.slice(0, visibleNewsCount).map((item, index) => (
      <Link to={item.url} target="_blank" key={index} className="newsItem">
        <img src={item.image_url} alt="Thumbnail" className="thumbnail" />
        <div className="newsContent">
          <div className="newsTitle"> {truncateTitle(item.title, 8)}</div>
          <p className="newsDescription">
            {truncateDescription(item.description, 20)}
          </p>
          <span className="date">
            Published on: {formatDate(item.news_time)}
          </span>
        </div>
      </Link>
    ));
  };

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    loop: true,
    speed: 5000,
    arrow: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="curatedNews">
      <h2 className="px-3" style={{ color: "#063d4d" }}>
        Chai &amp; Charts: Daily Economic News for NRIs.
      </h2>
      <p className="subTitleText mb-3">
        Stay up to date with the Indian Market, providing the most recent
        updates and news related to the Indian economy, specially tailored for
        our Ambassadors
      </p>
      <div style={{ position: "relative" }}>
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <button style={{ backgroundColor: "#416052" }} disabled>
              Loading...
            </button>
          </div>
        ) : (
          <Slider {...settings}>{renderNews()}</Slider>
        )}
      </div>
    </section>
  );
};

export default NewsSection;
