import "./stockModule.css";
import Lottie from "lottie-react";
import DesktopAnimation from "../../components/animations/coin-drop.json";
import OtherAnimation from "../../components/animations/color-change";
import { Row, Col } from "react-bootstrap";
const AmInvestStockBundle = () => {
  const desktopBannerAnimation = {
    loop: true,
    autoplay: true,
    animationData: DesktopAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const OtherBannerAnimation = {
    loop: true,
    autoplay: true,
    animationData: OtherAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <section className="stockBundles">
      <div className="ourServices">
        <Row>
          {" "}
          <h3 className="text-left mb-3 mainHeading">Products & Services</h3>
          <Row className="fundsCardRow">
            {/* {fundCategories.map(({ title, image, link, description }, index) => (
            <Col md={3} key={index}>
              <AmInvestCard
                type="fundsCard"
                image={image}
                pillText={title}
                title={title}
                description={description}
              />
            </Col>
          ))} */}
            <Col className="services-name">Mutual Funds</Col>
            <Col className="services-name">Bond & FD</Col>

            <Col className="services-name">IPO</Col>
            <Col className="services-name">NPS</Col>

            <Col className="services-name">Equity and Derivatives</Col>
            <Col className="services-name">Commodities</Col>
            <Col className="services-name">Trading View</Col>
            <Col className="services-name">Life Insurance</Col>
            <span>& many more</span>
            <div className="view-more-services arrowRightSvg">
              <span className="spanStylesOne">
                Take a look
                {/* <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg> */}
              </span>
            </div>
          </Row>
        </Row>
        <Lottie
          animationData={desktopBannerAnimation.animationData}
          loop={true}
          height={60}
          width={60}
          className="animationCoinDrop"
        />
      </div>
      <div className="ourServices">
        <Lottie
          animationData={OtherBannerAnimation.animationData}
          loop={true}
          height={60}
          width={60}
          className="animationCoinDrop"
        />
        <Row>
          {" "}
          <h3 className="text-left mb-3 mainHeading">Research</h3>
          <Row className="fundsCardRow">
            {/* {fundCategories.map(({ title, image, link, description }, index) => (
            <Col md={3} key={index}>
              <AmInvestCard
                type="fundsCard"
                image={image}
                pillText={title}
                title={title}
                description={description}
              />
            </Col>
          ))} */}
            <Col className="services-name">Key Features</Col>
            <Col className="services-name">Equity Reports</Col>
            <Col className="services-name">Commodity and Currency Reports</Col>
            <Col className="services-name">Institutional Research</Col>
            <Col className="services-name">Retail Research</Col>
            <Col className="services-name">Retail Disclaimer</Col>
            <span>& many more</span>
            <div className="view-more-services arrowRightSvg">
              <span className="spanStylesTwo">
                Take a look
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </span>
            </div>{" "}
          </Row>
        </Row>
      </div>
    </section>
  );
};

export default AmInvestStockBundle;
