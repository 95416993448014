import { Routes, Route } from "react-router-dom";
import Footer from "./components/inc/Footer";
import Screener from "./components/pages/Screener";
import Apin from "./components/pages/Apin";
import MutualFunds from "./components/pages/mutualFunds-detail";
import Portfoliio from "./components/pages/Portfoliio";
import MyAccount from "./components/pages/My-account";
import KYC from "./components/pages/Kyc";
import ChangePassword from "./components/pages/(auth)/changePassword";
import BankAccount from "./components/pages/bank-account";
import Onboarding from "./components/pages/(auth)/onboarding";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import SigninPage from "./components/pages/(auth)/sign-in";
import ProfileProcess from "./components/pages/profile-process";
import MyComponent from "./assets/index";
import TermsAndConditions from "./components/terms-and-conditions/Terms&Conditions";
import UserPolicy from "./components/terms-and-conditions/User-Policy";
import NomineeDetails from "./components/pages/nominee-details";
import ScrollToTop from "./scrollToTop";
import AccountConfirmationPage from "./components/pages/account-confirmation";
import ForgotPassword from "./components/pages/(auth)/forgotPassword";
import VerifyForgotPassword from "./components/pages/verifyForgotPassword";
import BlogsHomePage from "./components/pages/blogs";
import BlogPage from "./components/pages/blogs/[blogName]";
import AmInvestCalulators from "./components/pages/(calculators)/calculators";
import Navbar from "./modules/navBar/navBar";
import SipCalculator from "./feature/calculators/sipCalculator";
import LumpsumCalculator from "./feature/calculators/lumpsumCalculator";
import ROICalculator from "./feature/calculators/roiCalculator";
import FaqsPage from "./components/pages/faqs";
import Dashboard from "./components/pages/dashboard";
import ProtectedRoute from "./components/hoc/protectedRoute";
import AboutUs from "./components/pages/about-us";
import TaxServices from "./modules/taxFilings/taxServices";
import CompareFunds from "./modules/compareFunds/compareFunds";
import Testimonial from "./components/pages/sucessStories";
import AngelInvestments from "./components/pages/(angelInvestments)/angle-investments";
import CompanyName from "./components/pages/(angelInvestments)/[companyName]";
function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/onboarding"
          element={
            <WithHeaderAndFooter>
              <Onboarding />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/my-account"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <MyAccount />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/mutualFunds-detail/:id"
          element={
            // <ProtectedRoute>
            <WithHeaderAndFooter>
              <MutualFunds />
            </WithHeaderAndFooter>
            // </ProtectedRoute>
          }
        />
        <Route
          path="/portfolio"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <Portfoliio />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/apin"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <Apin />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/kyc"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <KYC />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/bank-account"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <BankAccount />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/nominee-details"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <NomineeDetails />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <ChangePassword />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile-process"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <ProfileProcess />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />

        <Route
          path="/mutualFunds"
          element={
            <WithHeaderAndFooter>
              <Screener />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/angel-investments"
          element={
            <WithHeaderAndFooter>
              <AngelInvestments />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/angel-investments/:id"
          element={
            <WithHeaderAndFooter>
              <CompanyName />
            </WithHeaderAndFooter>
          }
        />

        <Route
          path="/successStories"
          element={
            <WithHeaderAndFooter>
              <Testimonial />
            </WithHeaderAndFooter>
          }
        />

        <Route
          path="/compare-funds"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <CompareFunds />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/signin"
          element={
            <WithHeaderAndFooter>
              <SignIn />
            </WithHeaderAndFooter>
          }
        /> */}
        <Route
          path="/sign-in"
          element={
            <WithHeaderAndFooter>
              <SigninPage />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/terms&conditions"
          element={
            <WithHeaderAndFooter>
              <TermsAndConditions />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/user-policy"
          element={
            <WithHeaderAndFooter>
              <UserPolicy />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/account_confirmation/:token"
          element={
            <WithHeaderAndFooter>
              <AccountConfirmationPage />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/forgot_password"
          element={
            <WithHeaderAndFooter>
              <ForgotPassword />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/password_reset/:token"
          element={
            <WithHeaderAndFooter>
              <VerifyForgotPassword />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/blogs"
          element={
            <WithHeaderAndFooter>
              <BlogsHomePage />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/blogs/:blogName"
          element={
            <WithHeaderAndFooter>
              <BlogPage />
            </WithHeaderAndFooter>
          }
        />
        {/* <Route
          path="/filings"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <AmInvestTaxFiling />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/tax-services"
          element={
            <WithHeaderAndFooter>
              <TaxServices />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/calculators"
          element={
            <WithHeaderAndFooter>
              <AmInvestCalulators />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/calculators/sip"
          element={
            <WithHeaderAndFooter>
              <SipCalculator />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/calculators/lumpsum"
          element={
            <WithHeaderAndFooter>
              <LumpsumCalculator />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/calculators/roi"
          element={
            <WithHeaderAndFooter>
              <ROICalculator />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <WithHeaderAndFooter>
                <Dashboard />
              </WithHeaderAndFooter>
            </ProtectedRoute>
          }
        />
        <Route
          path="/faqs"
          element={
            <WithHeaderAndFooter>
              <FaqsPage />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/"
          element={
            <WithHeaderAndFooter>
              <MyComponent />
            </WithHeaderAndFooter>
          }
        />
        <Route
          path="/about-us"
          element={
            <WithHeaderAndFooter>
              <AboutUs />
            </WithHeaderAndFooter>
          }
        />
      </Routes>
    </>
  );
}

const WithHeaderAndFooter = ({ children }) => {
  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default App;
