import { useState, useEffect } from "react";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi";
import { toast } from "react-toastify";
import validator from "validator";
import { useAuth } from "../../context/authContext.js";
import "react-toastify/dist/ReactToastify.css";
import "./authStyles.css";
import Loader from "../../../feature/loader/loader.js";
import { Helmet } from "react-helmet";

axios.defaults.withCredentials = true;

function SignIn({ switchMode, onSignIn }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { login } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    Email: "",
    Password: "",
    rememberMe: false,
  });

  const [formValid, setFormValid] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [resendEmail, setResendEmail] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [sessionsformData, setSessionsFormData] = useState({
    status: "",
    platform: "",
    deviceName: "",
    locationIp: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    createAndSendUserSessionData();
  }, []);

  useEffect(() => {
    const { Email, Password } = formData;
    const isValidEmail = validator.isEmail(Email);
    const isValidPassword = Password.trim() !== "";
    setFormValid(isValidEmail && isValidPassword);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));

    let errors = {};

    if (name === "Email" && !validator.isEmail(value)) {
      errors.Email = "Invalid email address";
    } else if (name === "Password" && value.trim() === "") {
      errors.Password = "Password is required";
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
  };

  const handleResendEmail = async () => {
    try {
      await axios.post(`${baseUrl}/resendemail`, { Email: formData.Email });
      setEmailNotVerified(false);
      setResendEmail(true);
      toast.success("Verification email resent successfully");
    } catch (error) {
      toast.error("Failed to resend email");
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formValid) {
      setIsLoading(true);
      try {
        const combinedData = { ...formData, ...sessionsformData };
        const res = await axios.post(`${baseUrl}/signin`, combinedData);

        if (res.data.success) {
          const token = res.data.token;
          login(token);
          toast.success("Login successful!");

          const kycResponse = await axios.get(`${baseUrl}/getUserData`, {
            headers: { WRGSJGEUWCQN58EGV92D: `Bearer ${token}` },
          });
          const kycStatus = kycResponse.data.members?.KYCStatus || 0;

          if (kycStatus === 1 || kycStatus === 3) {
            navigate("/dashboard");
          } else {
            navigate("/profile-process#kyc-check");
          }
        } else {
          handleErrors(res.data.errors);
        }
      } catch (err) {
        console.error("Error in handleSubmit:", err);
        toast.error(
          "An error occurred while processing your request. Please try again later"
        );
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleErrors = (errors) => {
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        toast.error(error);
      });
      if (errors.includes("Email not verified")) {
        setEmailNotVerified(true);
      }
    } else {
      toast.error(
        "An error occurred while processing your request. Please try again later"
      );
    }
  };

  const formatDeviceName = () => {
    const userAgent = navigator.userAgent;
    const matches = userAgent.match(/\(([^)]+)\)/);
    return matches ? matches[1] : "Unknown";
  };

  const getIPAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (response.ok) {
        const data = await response.json();
        return data.ip;
      } else {
        console.error("Failed to fetch IP address, using default IP");
        return "0.0.0.0"; // Default IP address
      }
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return "0.0.0.0"; // Default IP address
    }
  };

  const isAppEnvironment = () => {
    return typeof window.MyAppBridge !== "undefined";
  };

  const createAndSendUserSessionData = async () => {
    const status = "active";
    const platform = isAppEnvironment() ? "app" : "web";
    const deviceName = formatDeviceName();
    const locationIp = (await getIPAddress()) || "0.0.0.0";

    setSessionsFormData({
      status,
      platform,
      deviceName,
      locationIp,
    });
  };

  const handleLoginWithGoogle = async () => {
    try {
      await createAndSendUserSessionData();
      const { status, platform, deviceName, locationIp } = sessionsformData;
      const redirectUrl = `${baseUrl}/auth/google?status=${status}&platform=${platform}&deviceName=${deviceName}&locationIp=${locationIp}`;

      const newWindow = window.open(
        redirectUrl,
        "GoogleLogin",
        "width=600,height=600"
      );

      const receiveMessage = (event) => {
        if (event.origin !== baseUrl) return;
        if (event.data.success) {
          newWindow.close();
          toast.success("Login successful!");
        } else {
          toast.error("Google login failed.");
        }
        window.removeEventListener("message", receiveMessage);
      };

      window.addEventListener("message", receiveMessage);
    } catch (error) {
      console.error("Error in handleLogin:", error);
    }
  };

  const handleRegisterClick = () => {
    if (switchMode) {
      switchMode("onboarding");
    } else {
      navigate("/onboarding");
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href="https://ambassadorinvestments.com/sign-in"
        />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          {/* <h2 className="text-success text-center mb-3 mt-3">
            Welcome to Ambassador
          </h2> */}
          <Row className="justify-content-center">
            <div body className="formComponent">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email*</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder=""
                    className="required"
                    required
                    name="Email"
                    value={formData.Email}
                    onChange={handleInputChange}
                    isinvalid={!!formErrors.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formErrors.Email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password*</Form.Label>
                  <div style={{ position: "relative" }}>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder=""
                      className="required"
                      required
                      name="Password"
                      value={formData.Password}
                      onChange={handleInputChange}
                      maxLength={50}
                    />
                    <span
                      className="psw-icon"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <BiShow size={30} />
                      ) : (
                        <BiHide size={30} />
                      )}
                    </span>
                  </div>
                </Form.Group>

                {emailNotVerified && (
                  <Alert variant="danger">
                    Your email address hasn't been verified yet. Please{" "}
                    <strong>
                      <u>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={handleResendEmail}
                        >
                          click here
                        </span>
                      </u>
                    </strong>{" "}
                    to resend email.
                  </Alert>
                )}
                {resendEmail && (
                  <Alert variant="success">Email sent successfully!</Alert>
                )}
                <Row>
                  <Col md={12}>
                    <div className="row mb-4">
                      <div className="col d-flex justify-content-center">
                        <div className="form-check">
                          {/* <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="form2Example31"
                        name="rememberMe"
                        checked={formData.rememberMe}
                        onChange={handleInputChange}
                      />{" "}
                      &nbsp;
                      <label
                        className="form-check-label me-3"
                        htmlFor="form2Example31"
                      >
                        Remember me
                      </label> */}
                        </div>
                      </div>
                      <div className="col">
                        <Link to="/forgot_password" className="fgPass">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="submitButton d-block"
                      disabled={!formValid}
                    >
                      Login
                    </button>
                  </Col>
                </Row>
                <Row>
                  <div className="text-center">
                    <br />
                    <p>
                      Not a member?{" "}
                      <span
                        className="register-link"
                        onClick={handleRegisterClick}
                      >
                        Register
                      </span>
                    </p>
                    <p>or sign in with:</p>

                    <Col md={12} className="d-flex justify-content-center">
                      <div
                        className={`googleButton my-3`}
                        onClick={handleLoginWithGoogle}
                      >
                        <span>
                          <img
                            style={{ width: "24px", height: "24px" }}
                            src="/images/googleIcon.svg"
                            alt="login with google"
                          />
                          Sign in with Google
                        </span>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Form>
            </div>
          </Row>
        </Container>
      )}
    </>
  );
}

export default SignIn;
