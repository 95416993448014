import { Link } from "react-router-dom";
import "./amInvestButton.css";

const AmInvestButtons = ({
  title,
  buttonType = "dark",
  className = "",
  onClick,
  loading,
  disabled,
  href,
  id,
  icon,
  src,
  alt,
}) => {
  const buttonClass = `button ${buttonType} ${className}`;

  const ButtonContent = (
    <button
      onClick={onClick}
      className={`${buttonClass} border-animation`}
      disabled={disabled || loading}
      id={id}
    >
      {icon && (
        <div className="imageBlock me-1">
          <img src={src} alt={alt} width={32} height={32} />
        </div>
      )}
      <span>{title}</span>
    </button>
  );

  if (href) {
    return <Link to={href}>{ButtonContent}</Link>;
  }

  return ButtonContent;
};

export default AmInvestButtons;
